<template>
  <div class="column content-box">
    <report-filters
      v-if="inboxes"
      :should-date-range-be-shown="false"
      type="inbox"
      :filter-items-list="inboxes"
      :should-filters-be-flexible="true"
      @filter-change="onInboxFilterChange"
    />
    <div class="row">
      <div class="column small-12 medium-6 conversation-metric">
        <metric-card
          :header="$t('OVERVIEW_REPORTS.ACCOUNT_CONVERSATIONS.HEADER')"
          :is-loading="uiFlags.isFetchingAccountConversationMetric"
          :loading-message="
            $t('OVERVIEW_REPORTS.ACCOUNT_CONVERSATIONS.LOADING_MESSAGE')
          "
          :show-live-badge="false"
        >
          <div
            v-for="(metric, name, index) in accountConversationMetrics"
            :key="index"
            class="metric-content column"
          >
            <h3 class="heading">
              {{ name }}
            </h3>
            <p class="metric">
              {{ metric }}
            </p>
          </div>
        </metric-card>
      </div>
      <div class="column small-12 medium-6">
        <metric-card
          :show-live-badge="false"
          :header="$t('OVERVIEW_REPORTS.TODAY_CARD.HEADER')"
        >
          <div
            v-for="(metric, name, index) in todayMetrics"
            :key="index"
            class="metric-content column"
          >
            <h3 class="heading">
              {{ name }}
            </h3>
            <p
              class="metric"
              :data-test-id="`${name.toLowerCase()}_status_metric`"
            >
              {{ metric }}
            </p>
          </div>
        </metric-card>
      </div>
    </div>
    <div class="row">
      <metric-card data-test-id="supervisor-dashboard-graph">
        <woot-loading-state
          v-if="!isGraphDataLoaded"
          :message="$t('REPORT.LOADING_CHART')"
        />
        <div v-else class="chart-container">
          <woot-line
            v-if="isGraphDataLoaded"
            :collection="collectionMetrics"
            :chart-options="chartOptions"
          />
          <span v-else class="empty-state">
            {{ $t('REPORT.NO_ENOUGH_DATA') }}
          </span>
        </div>
      </metric-card>
    </div>
    <div class="row">
      <div class="column small-12 medium-6">
        <metric-card
          :header="$t('OVERVIEW_REPORTS.AGENT_STATUS.HEADER')"
          :show-live-badge="false"
        >
          <div
            v-for="(metric, name, index) in agentStatusMetrics"
            :key="index"
            class="metric-content column"
          >
            <h3 class="heading">
              {{ name }}
            </h3>
            <p
              class="metric"
              :data-test-id="`${name.toLowerCase()}_status_metric`"
            >
              {{ metric }}
            </p>
          </div>
        </metric-card>
      </div>
      <div class="column small-12 medium-6">
        <metric-card
          :header="$t('OVERVIEW_REPORTS.CONVERSATION_HANDLING_CARD.HEADER')"
          :show-live-badge="false"
        >
          <div
            v-for="(metric, name, index) in conversationHandlingMetrics"
            :key="index"
            class="metric-content column"
          >
            <h3 class="heading">
              {{ name }}
            </h3>
            <p
              class="metric-time"
              :data-test-id="`${name.toLowerCase()}_status_metric`"
            >
              {{ metric }}
            </p>
          </div>
        </metric-card>
      </div>
    </div>

    <div class="row">
      <metric-card
        :header="$t('OVERVIEW_REPORTS.AGENT_CONVERSATIONS.HEADER')"
        :has-filter="true"
        :filter-options="filterOptions"
        :selected-filter-item="selectedFilter"
        :filter-title="$t('OVERVIEW_REPORTS.AGENT_STATUS_FILTER.TITLE')"
        :show-live-badge="false"
        @filterClick="selectFilter"
      >
        <agent-table
          :total-agents="agentConversationMetricCount"
          :agent-metrics="agentConversationMetric"
          :page-index="pageIndex"
          :is-loading="uiFlags.isFetchingAgentConversationMetric"
          @page-change="onPageNumberChange"
        />
      </metric-card>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import AgentTable from './components/overview/AgentTable.vue';
import MetricCard from './components/overview/MetricCard.vue';
import { ORDERED_AGENT_STATUS_KEY_TRANSLATOR } from 'shared/constants/statuses';
import reportMixin from 'dashboard/mixins/reportMixin';
import ReportFilters from './components/ReportFilters.vue';

export default {
  name: 'LiveReports',
  components: {
    ReportFilters,
    AgentTable,
    MetricCard,
  },
  mixins: [reportMixin],
  data() {
    return {
      isGraphDataLoaded: false,
      pageIndex: 1,
      selectedFilterId: 'none',
      selectedInboxId: null,
      autoRefreshTimer: null,
    };
  },
  computed: {
    ...mapGetters({
      inboxes: 'inboxes/getInboxes',
      agentStatus: 'agents/getAgentStatus',
      agentsCount: 'agents/getAgentsCount',
      accountConversationMetric: 'getAccountConversationMetric',
      accountConversationTimeseriesMetric:
        'getAccountConversationTimeseriesMetric',
      agentConversationMetric: 'getAgentConversationMetric',
      agentConversationMetricCount: 'getAgentConversationMetricTotal',
      uiFlags: 'getOverviewUIFlags',
    }),
    selectedFilter() {
      const status =
        this.selectedFilterId === 'none'
          ? this.$t('OVERVIEW_REPORTS.AGENT_STATUS_FILTER.NONE')
          : this.$t(
              `OVERVIEW_REPORTS.AGENT_STATUS.${this.selectedFilterId.toUpperCase()}`
            );
      return {
        name: `${this.$t(
          'OVERVIEW_REPORTS.AGENT_STATUS_FILTER.TITLE'
        )}: ${status}`,
        id: this.selectedFilterId,
      };
    },
    agentStatusMetrics() {
      let metric = {};
      Object.keys(ORDERED_AGENT_STATUS_KEY_TRANSLATOR).forEach(key => {
        const metricName = this.$t(
          `OVERVIEW_REPORTS.AGENT_STATUS.${ORDERED_AGENT_STATUS_KEY_TRANSLATOR[key]}`
        );
        metric[metricName] = this.agentStatus[key];
      });
      return metric;
    },
    accountConversationMetrics() {
      return this.formatMetrics(
        'OVERVIEW_REPORTS.ACCOUNT_CONVERSATIONS',
        this.accountConversationMetric,
        ['open', 'queued', 'unassigned'],
        false
      );
    },
    todayMetrics() {
      return this.formatMetrics(
        'OVERVIEW_REPORTS.TODAY_CARD',
        this.accountConversationMetric.today,
        ['all'],
        false
      );
    },
    conversationHandlingMetrics() {
      return this.formatMetrics(
        'OVERVIEW_REPORTS.CONVERSATION_HANDLING_CARD',
        this.accountConversationMetric,
        ['avg_first_response_time', 'avg_resolution_time', 'avg_queue_time'],
        true
      );
    },
    filterOptions() {
      return [
        {
          name: this.$t('OVERVIEW_REPORTS.AGENT_STATUS_FILTER.NONE'),
          id: 'none',
        },
        { name: this.$t('OVERVIEW_REPORTS.AGENT_STATUS.AWAY'), id: 'away' },
        { name: this.$t('OVERVIEW_REPORTS.AGENT_STATUS.BUSY'), id: 'busy' },
        {
          name: this.$t('OVERVIEW_REPORTS.AGENT_STATUS.OFFLINE'),
          id: 'offline',
        },
        { name: this.$t('OVERVIEW_REPORTS.AGENT_STATUS.ONLINE'), id: 'online' },
      ];
    },
    collectionMetrics() {
      const chartData = {
        labels: this.accountConversationTimeseriesMetric.group_timestamps.map(
          timestamp => {
            const date = new Date(timestamp);

            return `${date.getHours()}:${date
              .getMinutes()
              .toString()
              .padStart(2, '0')}`;
          }
        ),
        datasets: [
          {
            label: this.$t(
              'OVERVIEW_REPORTS.METRICS.INCOMING_CONVERSATIONS.NAME'
            ),
            backgroundColor: 'transparent',
            borderColor: '#90CAF9',
            lineTension: 0.3,
            pointRadius: 0,
            fill: false,
            data: this.accountConversationTimeseriesMetric
              .incoming_conversation_counts,
          },
          {
            label: this.$t(
              'OVERVIEW_REPORTS.METRICS.CONVERSATION_DURATION.NAME'
            ),
            backgroundColor: 'transparent',
            borderColor: '#FFC237',
            lineTension: 0.3,
            pointRadius: 0,
            fill: false,
            data: this.accountConversationTimeseriesMetric
              .conversation_duration_averages,
          },
          {
            label: this.$t(
              'OVERVIEW_REPORTS.METRICS.QUEUED_CONVERSATIONS.NAME'
            ),
            backgroundColor: 'transparent',
            borderColor: '#163F99',
            lineTension: 0.3,
            pointRadius: 0,
            fill: false,
            data: this.accountConversationTimeseriesMetric
              .queued_conversation_counts,
          },
        ],
      };

      return chartData;
    },
    chartOptions() {
      const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      };
      return chartOptions;
    },
  },
  async mounted() {
    await this.$store.dispatch('agents/get');

    await this.fetchAllData();
    this.autoRefreshTimer = setInterval(this.fetchAllData, 60000);
  },
  methods: {
    onInboxFilterChange(payload) {
      if (payload) {
        this.selectedInboxId = payload.id;
      } else {
        this.selectedInboxId = null;
      }
      this.fetchAllData();
    },
    selectFilter(filter) {
      this.selectedFilterId = filter.id;
      this.fetchAgentConversationMetric();
    },
    formatMetrics(i18nPrefix, metricData, metricNames, isTimestamp) {
      let finalMetrics = {};

      metricNames.forEach(name => {
        const metricKey = this.$t(`${i18nPrefix}.${name.toUpperCase()}`);
        // metric data will be undefined on the initial render as it is still being fetched from the backend
        const data = metricData !== undefined ? metricData[name] : 0;
        finalMetrics[metricKey] = isTimestamp
          ? this.formatSecondsField(data)
          : data;
      });

      return finalMetrics;
    },
    async fetchAllData() {
      this.isGraphDataLoaded = false;
      await this.fetchAccountConversationMetric();
      await this.fetchAgentConversationMetric();
      await this.$store.dispatch('fetchAccountConversationTimeseries', {
        inboxId: this.selectedInboxId ? this.selectedInboxId : undefined,
      });
      this.isGraphDataLoaded = true;
    },
    async fetchAccountConversationMetric() {
      await this.$store.dispatch('fetchAccountConversationMetric', {
        type: 'account',
        inboxId: this.selectedInboxId ? this.selectedInboxId : undefined,
      });
    },
    async fetchAgentConversationMetric() {
      const statusFilterPayload =
        this.selectedFilterId === 'none'
          ? {}
          : { status: this.selectedFilterId };
      await this.$store.dispatch('fetchAgentConversationMetric', {
        type: 'agent',
        page: this.pageIndex,
        ...statusFilterPayload,
        inboxId: this.selectedInboxId ? this.selectedInboxId : undefined,
      });
    },
    onPageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.fetchAgentConversationMetric();
    },
  },
};
</script>
<style lang="scss" scoped>
.chart-container {
  width: 100%;
  > div {
    position: relative;
  }
}
</style>
