const wootConstants = {
  GRAVATAR_URL: 'https://www.gravatar.com/avatar/',
  ASSIGNEE_TYPE: {
    ME: 'me',
    UNASSIGNED: 'unassigned',
    ALL: 'all',
  },
  SIDE_PANEL_TAB: {
    DETAILS: 'details',
    ASSIST: 'assist',
  },
  STATUS_TYPE: {
    DELETED: 'deleted',
    OPEN: 'open',
    RESOLVED: 'resolved',
    PENDING: 'pending',
    SNOOZED: 'snoozed',
    ABANDONED: 'abandoned',
    ALL: 'all',
  },
  SNACKBAR_TYPE: {
    SUCCESS: 'success',
    ERROR: 'error',
  },
  SORT_BY_TYPE: {
    LATEST: 'latest',
    CREATED_AT: 'sort_on_created_at',
    PRIORITY: 'sort_on_priority',
  },
};
export const DEFAULT_REDIRECT_URL = '/app/';
export default wootConstants;

export { wootConstants };
