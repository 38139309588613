export const getters = {
  getNotifications($state: {
    records: {
      [x: string]: {
        id: number;
      };
    };
  }) {
    return Object.values($state.records).sort((n1, n2) => n2.id - n1.id);
  },
  getUIFlags($state: { uiFlags: unknown }) {
    return $state.uiFlags;
  },
  getNotification:
    ($state: { records: { [x: string]: unknown } }) =>
    (id: string | number) => {
      const notification = $state.records[id];
      return notification || {};
    },
  getMeta: ($state: { meta: unknown }) => {
    return $state.meta;
  },
};
