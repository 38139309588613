import ApiClient, { axiosWithAuth } from './ApiClient';

class Agents extends ApiClient {
  constructor() {
    super('agents', { accountScoped: true });
  }

  getUnmaskedAgents() {
    const finalUrl = `${this.url}?agent_masking_disabled=true`;
    return axiosWithAuth.get(finalUrl);
  }

  updateAgentSkills({ agentId, skills }) {
    return axiosWithAuth.patch(`${this.url}/${agentId}/skills`, {
      skill_ids: skills,
    });
  }
}

export default new Agents();
