import { MESSAGE_TYPE } from 'shared/constants/messages';
import { IFrameHelper } from 'widget/helpers/utils';

import { showBadgeOnFavicon } from './faviconHelper';

export const initOnEvents = ['click', 'touchstart', 'keypress', 'keydown'];

export const getAlertAudio = async (baseUrl = '', type = 'dashboard') => {
  const audioCtx = new (window.AudioContext || window.webkitAudioContext)();

  window.playAudioAlert = async () => {
    // Dynamically get the current account's ID and sound settings
    const id = window.WOOT.$store.getters.getCurrentAccount.id;
    const accountSettings =
      window.WOOT.$store.getters['accounts/getAccount'](id).custom_attributes;
    const soundUrl = accountSettings.sound_url;
    const notificationType = accountSettings.notification_sound_type;

    const soundToPlay =
      notificationType === 'custom'
        ? soundUrl
        : `${baseUrl}/audio/${type}/ding.mp3`;

    // Fetch and play the sound dynamically
    try {
      const audioRequest = new Request(soundToPlay);
      const response = await fetch(audioRequest);
      const buffer = await response.arrayBuffer();
      audioCtx.decodeAudioData(buffer, audioBuffer => {
        const source = audioCtx.createBufferSource();
        source.buffer = audioBuffer;
        source.connect(audioCtx.destination);
        source.loop = false;
        source.start();
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error playing audio:', error);
    }
  };
};

export const notificationEnabled = (enableAudioAlerts, id, userId) => {
  if (enableAudioAlerts === 'mine') {
    return userId === id;
  }
  if (enableAudioAlerts === 'all') {
    return true;
  }
  return false;
};

export const shouldPlayAudio = (
  message,
  conversationId,
  userId,
  isDocHidden
) => {
  const {
    conversation_id: incomingConvId,
    sender_id: senderId,
    sender_type: senderType,
    message_type: messageType,
    private: isPrivate,
  } = message;
  const isFromCurrentUser = userId === senderId && senderType === 'User';

  const playAudio =
    !isFromCurrentUser && (messageType === MESSAGE_TYPE.INCOMING || isPrivate);
  if (isDocHidden) return playAudio;
  if (conversationId !== incomingConvId) return playAudio;
  return false;
};

export const getAssigneeFromNotification = currentConv => {
  let id;
  if (currentConv.meta) {
    const assignee = currentConv.meta.assignee;
    if (assignee) {
      id = assignee.id;
    }
  }
  return id;
};
export const newMessageNotification = data => {
  const { conversation_id: currentConvId } = window.WOOT.$route.params;
  const currentUserId = window.WOOT.$store.getters.getCurrentUserID;
  const { conversation_id: incomingConvId } = data;
  const currentConv =
    window.WOOT.$store.getters.getConversationById(incomingConvId) || {};
  const assigneeId = getAssigneeFromNotification(currentConv);
  const isDocHidden = document.hidden;
  const { enable_audio_alerts: enableAudioAlerts = false } =
    window.WOOT.$store.getters.getUISettings;
  const playAudio = shouldPlayAudio(
    data,
    Number(currentConvId),
    currentUserId,
    isDocHidden
  );
  const isNotificationEnabled = notificationEnabled(
    enableAudioAlerts,
    currentUserId,
    assigneeId
  );

  // Show badge icon although audio is disabled
  if (data.message_type === MESSAGE_TYPE.INCOMING && !currentConv.muted) {
    showBadgeOnFavicon();
  }

  if (playAudio && isNotificationEnabled) {
    window.playAudioAlert();
  }
};

export const playNewMessageNotificationInWidget = () => {
  IFrameHelper.sendMessage({
    event: 'playAudio',
  });
};
