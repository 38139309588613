import capitalize from './capitalize';

export default (key, value) => {
  const mapKeyValueToFilter = {
    age: () => (value === 0 ? '' : value),
    gender: () => capitalize(value),
    accepted_gdpr: () => (value ? 'Yes' : 'No'),
    goals: () =>
      Array.isArray(value) && value.length === 0 ? '' : value.toString(),
  };

  if (typeof value === 'object' && !Array.isArray(value)) {
    return value.value ? value.value : '';
  }
  return mapKeyValueToFilter[key] ? mapKeyValueToFilter[key]() : value;
};
