export default profileKey => {
  const profileKeyToHumanString = {
    firstname: 'CONTACT_PROFILE.FIRST_NAME',
    lastname: 'CONTACT_PROFILE.LAST_NAME',
    profilepic: 'CONTACT_PROFILE.PROFILE_PICTURE',
    prevent_data_collection: 'CONTACT_PROFILE.PREVENT_DATA_COLLECTION',
    accepted_gdpr: 'CONTACT_PROFILE.ACCEPTED_GDPR',
    age: 'CONTACT_PROFILE.AGE',
    birthday: 'CONTACT_PROFILE.BIRTHDAY',
    gender: 'CONTACT_PROFILE.GENDER',
    location: 'CONTACT_PROFILE.LOCATION',
    goals: 'CONTACT_PROFILE.GOALS',
  };

  return profileKeyToHumanString[profileKey] || profileKey;
};
