<template>
  <div class="columns profile--settings">
    <form v-if="!uiFlags.isFetchingItem" @submit.prevent="updateAccount">
      <div class="small-12 row profile--settings--row">
        <div class="columns small-3">
          <h4 class="block-title">
            {{ $t('GENERAL_SETTINGS.FORM.GENERAL_SECTION.TITLE') }}
          </h4>
          <p>{{ $t('GENERAL_SETTINGS.FORM.GENERAL_SECTION.NOTE') }}</p>
        </div>
        <div class="columns small-9 medium-5">
          <div class="main-settings-section">
            <label :class="{ error: $v.name.$error }">
              {{ $t('GENERAL_SETTINGS.FORM.NAME.LABEL') }}
              <input
                v-model="name"
                type="text"
                data-test-id="account-settings-input-name"
                :placeholder="$t('GENERAL_SETTINGS.FORM.NAME.PLACEHOLDER')"
                @blur="$v.name.$touch"
              />
              <span v-if="$v.name.$error" class="message">
                {{ $t('GENERAL_SETTINGS.FORM.NAME.ERROR') }}
              </span>
            </label>
            <label :class="{ error: $v.locale.$error }">
              {{ $t('GENERAL_SETTINGS.FORM.LANGUAGE.LABEL') }}
              <select
                v-model="locale"
                data-test-id="account-settings-select-language"
              >
                <option
                  v-for="lang in languagesSortedByCode"
                  :key="lang.iso_639_1_code"
                  :value="lang.iso_639_1_code"
                >
                  {{ lang.name }}
                </option>
              </select>
              <span v-if="$v.locale.$error" class="message">
                {{ $t('GENERAL_SETTINGS.FORM.LANGUAGE.ERROR') }}
              </span>
            </label>
          </div>
          <div class="number-input-section">
            <number-input
              v-model="maxAgentConversations"
              data-test-id="account-settings-select-max-agent-conversations"
              :error-message="
                $t('GENERAL_SETTINGS.FORM.MAX_AGENT_CONVERSATIONS.ERROR')
              "
              :none-input-label="
                $t('GENERAL_SETTINGS.FORM.MAX_AGENT_CONVERSATIONS.NONE_LABEL')
              "
              :custom-input-label="
                $t('GENERAL_SETTINGS.FORM.MAX_AGENT_CONVERSATIONS.CUSTOM_LABEL')
              "
              :general-label="
                $t('GENERAL_SETTINGS.FORM.MAX_AGENT_CONVERSATIONS.LABEL')
              "
              :lower-guard="1"
              :upper-guard="999"
            />
          </div>
          <div class="number-input-section">
            <number-input
              v-model="autoSetAway"
              data-test-id="account-settings-select-auto-set-away"
              :error-message="$t('GENERAL_SETTINGS.FORM.AUTO_SET_AWAY.ERROR')"
              :none-input-label="
                $t('GENERAL_SETTINGS.FORM.AUTO_SET_AWAY.NONE_LABEL')
              "
              :custom-input-label="
                $t('GENERAL_SETTINGS.FORM.AUTO_SET_AWAY.CUSTOM_LABEL')
              "
              :general-label="$t('GENERAL_SETTINGS.FORM.AUTO_SET_AWAY.LABEL')"
              :lower-guard="1"
              :upper-guard="999"
            />
          </div>
          <div>
            <number-input
              v-model="autoLogoutTimespan"
              data-test-id="account-settings-set-auto-logout-timespan"
              :error-message="
                $t('GENERAL_SETTINGS.FORM.AUTO_LOGOUT_TIMESPAN.ERROR')
              "
              :none-input-label="
                $t('GENERAL_SETTINGS.FORM.AUTO_LOGOUT_TIMESPAN.NONE_LABEL')
              "
              :custom-input-label="
                $t('GENERAL_SETTINGS.FORM.AUTO_LOGOUT_TIMESPAN.CUSTOM_LABEL')
              "
              :general-label="
                $t('GENERAL_SETTINGS.FORM.AUTO_LOGOUT_TIMESPAN.LABEL')
              "
              :lower-guard="1"
              :upper-guard="60"
            />
          </div>
          <div class="number-input-section">
            <number-input
              v-model="attachmentTtl"
              data-test-id="account-settings-select-attachment-ttl"
              :error-message="$t('GENERAL_SETTINGS.FORM.ATTACHMENT_TTL.ERROR')"
              :none-input-label="
                $t('GENERAL_SETTINGS.FORM.ATTACHMENT_TTL.NONE_LABEL')
              "
              :custom-input-label="
                $t('GENERAL_SETTINGS.FORM.ATTACHMENT_TTL.CUSTOM_LABEL')
              "
              :general-label="$t('GENERAL_SETTINGS.FORM.ATTACHMENT_TTL.LABEL')"
              :lower-guard="1"
              :upper-guard="999"
            />
          </div>

          <div class="number-input-section">
            <number-input
              v-model="autoResolveDuration"
              data-test-id="account-settings-select-auto-resolve-duration"
              :error-message="
                $t('GENERAL_SETTINGS.FORM.AUTO_RESOLVE_DURATION.ERROR')
              "
              :none-input-label="
                $t('GENERAL_SETTINGS.FORM.AUTO_RESOLVE_DURATION.NONE_LABEL')
              "
              :custom-input-label="
                $t('GENERAL_SETTINGS.FORM.AUTO_RESOLVE_DURATION.CUSTOM_LABEL')
              "
              :general-label="
                $t('GENERAL_SETTINGS.FORM.AUTO_RESOLVE_DURATION.LABEL')
              "
              :lower-guard="1"
              :upper-guard="999"
            />
          </div>
          <div class="auto-resolve-action-container">
            <label>
              {{ $t('GENERAL_SETTINGS.FORM.AUTO_RESOLVE_ACTION.LABEL') }}
              <select
                v-model="autoResolveAction"
                data-test-id="account-settings-select-auto-resolve-action"
              >
                <option
                  v-for="action in ['resolve', 'abandon']"
                  :key="action"
                  :value="action"
                >
                  {{
                    $t(
                      `GENERAL_SETTINGS.FORM.AUTO_RESOLVE_ACTION.${action.toUpperCase()}`
                    )
                  }}
                </option>
              </select>
              <span>
                {{ $t('GENERAL_SETTINGS.FORM.AUTO_RESOLVE_ACTION.NOTE') }}
              </span>
              <span v-if="$v.locale.$error" class="message">
                {{ $t('GENERAL_SETTINGS.FORM.AUTO_RESOLVE_ACTION.ERROR') }}
              </span>
            </label>
          </div>
          <div class="toggle-checkbox-container">
            <input
              id="auto_delete_on_resolve"
              v-model="shouldAutoDeleteOnResolve"
              class="notification--checkbox"
              type="checkbox"
            />
            <label
              for="auto_delete_on_resolve"
              class="toggle-checkbox-label-container"
            >
              <div>
                {{
                  $t('GENERAL_SETTINGS.FORM.AUTO_DELETE_ON_RESOLVE.MAIN_LABEL')
                }}
              </div>
              <div class="warning-label">
                {{
                  $t(
                    'GENERAL_SETTINGS.FORM.AUTO_DELETE_ON_RESOLVE.WARNING_LABEL'
                  )
                }}
              </div>
            </label>
          </div>
          <div class="toggle-checkbox-container">
            <input
              id="toggle_agent_masking"
              v-model="isAgentMaskingEnabled"
              data-test-id="account-settings-toggle-agent-masking"
              class="notification--checkbox"
              type="checkbox"
            />
            <label
              for="toggle_agent_masking"
              class="toggle-checkbox-label-container"
            >
              <div>
                {{ $t('GENERAL_SETTINGS.FORM.TOGGLE_AGENT_MASKING') }}
              </div>
            </label>
          </div>
          <div class="toggle-checkbox-container">
            <input
              id="toggle_assign_to_busy_agent"
              v-model="assignToBusyAgent"
              data-test-id="account-settings-toggle_assign_to_busy_agent"
              class="notification--checkbox"
              type="checkbox"
            />
            <label
              for="toggle_assign_to_busy_agent"
              class="toggle-checkbox-label-container"
            >
              <div>
                {{
                  $t('GENERAL_SETTINGS.FORM.ASSIGN_CONVERSATION_TO_BUSY_AGENT')
                }}
              </div>
            </label>
          </div>
        </div>
      </div>
      <div
        v-if="featureUseAICopilotWorkspace"
        class="profile--settings--row row"
      >
        <div class="columns small-3">
          <h4 class="block-title">
            {{
              $t('PROFILE_SETTINGS.FORM.AGENT_ASSIST_WORKSPACE_SECTION.TITLE')
            }}
          </h4>
          <p>
            {{
              $t('PROFILE_SETTINGS.FORM.AGENT_ASSIST_WORKSPACE_SECTION.NOTE')
            }}
          </p>
        </div>
        <div class="columns small-9">
          <input
            id="toggle_override_agent_assist_workspace"
            v-model="overrideEnableAawView"
            data-test-id="account-settings-toggle-override-ai-copilot-workspace"
            class="notification--checkbox"
            type="checkbox"
          />
          <label
            for="toggle_override_agent_assist_workspace"
            class="toggle-checkbox-label-container"
          >
            <div>
              {{
                $t(
                  'GENERAL_SETTINGS.FORM.TOGGLE_OVERRIDE_AGENT_ASSIST_WORKSPACE'
                )
              }}
            </div>
            <div class="warning-label">
              {{
                $t(
                  'GENERAL_SETTINGS.FORM.TOGGLE_OVERRIDE_AGENT_ASSIST_WORKSPACE_NOTE'
                )
              }}
            </div>
          </label>
          <div>
            <input
              id="toggle_agent_assist_workspace"
              v-model="enableAawView"
              data-test-id="account-settings-toggle-ai-copilot-workspace"
              class="notification--checkbox"
              type="checkbox"
              :disabled="!overrideEnableAawView"
            />
            <label
              for="toggle_agent_assist_workspace"
              class="toggle-checkbox-label-container"
            >
              <div>
                {{ $t('GENERAL_SETTINGS.FORM.TOGGLE_AGENT_ASSIST_WORKSPACE') }}
              </div>
            </label>
          </div>
        </div>
      </div>
      <div class="profile--settings--row row">
        <div class="columns small-3">
          <h4 class="block-title">
            {{ $t('PROFILE_SETTINGS.FORM.EMAIL_NOTIFICATIONS_SECTION.TITLE') }}
          </h4>
          <p>
            {{ $t('PROFILE_SETTINGS.FORM.EMAIL_NOTIFICATIONS_SECTION.NOTE') }}
          </p>
        </div>
        <div class="columns small-9">
          <input
            id="toggle_email_notifications"
            v-model="emailNotificationEnabled"
            class="notification--checkbox"
            type="checkbox"
          />
          <label
            for="toggle_email_notifications"
            class="toggle-checkbox-label-container"
          >
            <div>
              {{
                $t(
                  'PROFILE_SETTINGS.FORM.EMAIL_NOTIFICATIONS_SECTION.ENABLE_FOR_ACCOUNT_LEVEL'
                )
              }}
            </div>
            <div class="warning-label">
              {{
                $t(
                  'PROFILE_SETTINGS.FORM.EMAIL_NOTIFICATIONS_SECTION.ENABLE_FOR_ACCOUNT_LEVEL_NOTE'
                )
              }}
            </div>
          </label>
          <div>
            <input
              id="email_conversation_creation"
              v-model="selectedEmailFlags"
              class="notification--checkbox"
              type="checkbox"
              value="email_conversation_creation"
              data-test-id="email_conversation_creation"
              :disabled="!emailNotificationEnabled"
              @input="handleEmailInput"
            />
            <label for="email_conversation_creation">
              {{
                $t(
                  'PROFILE_SETTINGS.FORM.EMAIL_NOTIFICATIONS_SECTION.CONVERSATION_CREATION'
                )
              }}
            </label>
          </div>

          <div>
            <input
              id="email_conversation_assignment"
              v-model="selectedEmailFlags"
              class="notification--checkbox"
              type="checkbox"
              value="email_conversation_assignment"
              data-test-id="email_conversation_assignment"
              :disabled="!emailNotificationEnabled"
              @input="handleEmailInput"
            />
            <label for="email_conversation_assignment">
              {{
                $t(
                  'PROFILE_SETTINGS.FORM.EMAIL_NOTIFICATIONS_SECTION.CONVERSATION_ASSIGNMENT'
                )
              }}
            </label>
          </div>

          <div>
            <input
              id="email_conversation_mention"
              v-model="selectedEmailFlags"
              class="notification--checkbox"
              type="checkbox"
              value="email_conversation_mention"
              data-test-id="email_conversation_mention"
              :disabled="!emailNotificationEnabled"
              @input="handleEmailInput"
            />
            <label for="email_conversation_mention">
              {{
                $t(
                  'PROFILE_SETTINGS.FORM.EMAIL_NOTIFICATIONS_SECTION.CONVERSATION_MENTION'
                )
              }}
            </label>
          </div>

          <div>
            <input
              id="email_assigned_conversation_new_message"
              v-model="selectedEmailFlags"
              class="notification--checkbox"
              type="checkbox"
              value="email_assigned_conversation_new_message"
              data-test-id="email_assigned_conversation_new_message"
              :disabled="!emailNotificationEnabled"
              @input="handleEmailInput"
            />
            <label for="email_assigned_conversation_new_message">
              {{
                $t(
                  'PROFILE_SETTINGS.FORM.EMAIL_NOTIFICATIONS_SECTION.ASSIGNED_CONVERSATION_NEW_MESSAGE'
                )
              }}
            </label>
          </div>
        </div>
      </div>
      <div class="profile--settings--row row push-row">
        <div class="columns small-3">
          <h4 class="block-title">
            {{ $t('PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.TITLE') }}
          </h4>
          <p>
            {{ $t('PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.NOTE') }}
          </p>
        </div>
        <div class="columns small-9">
          <input
            id="toggle_push_notifications"
            v-model="pushNotificationEnabled"
            class="notification--checkbox"
            type="checkbox"
          />
          <label
            for="pushNotificationEnabled"
            class="toggle-checkbox-label-container"
          >
            <div>
              {{
                $t(
                  'PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.ENABLE_FOR_ACCOUNT_LEVEL'
                )
              }}
            </div>
            <div class="warning-label">
              {{
                $t(
                  'PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.ENABLE_FOR_ACCOUNT_LEVEL_NOTE'
                )
              }}
            </div>
          </label>

          <div>
            <input
              id="push_conversation_creation"
              v-model="selectedPushFlags"
              class="notification--checkbox"
              type="checkbox"
              value="push_conversation_creation"
              data-test-id="push_conversation_creation"
              :disabled="!pushNotificationEnabled"
              @input="handlePushInput"
            />
            <label for="push_conversation_creation">
              {{
                $t(
                  'PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.CONVERSATION_CREATION'
                )
              }}
            </label>
          </div>

          <div>
            <input
              id="push_conversation_assignment"
              v-model="selectedPushFlags"
              class="notification--checkbox"
              type="checkbox"
              value="push_conversation_assignment"
              data-test-id="push_conversation_assignment"
              :disabled="!pushNotificationEnabled"
              @input="handlePushInput"
            />
            <label for="push_conversation_assignment">
              {{
                $t(
                  'PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.CONVERSATION_ASSIGNMENT'
                )
              }}
            </label>
          </div>

          <div>
            <input
              id="push_conversation_mention"
              v-model="selectedPushFlags"
              class="notification--checkbox"
              type="checkbox"
              value="push_conversation_mention"
              data-test-id="push_conversation_mention"
              :disabled="!pushNotificationEnabled"
              @input="handlePushInput"
            />
            <label for="push_conversation_mention">
              {{
                $t(
                  'PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.CONVERSATION_MENTION'
                )
              }}
            </label>
          </div>

          <div>
            <input
              id="push_assigned_conversation_new_message"
              v-model="selectedPushFlags"
              class="notification--checkbox"
              type="checkbox"
              value="push_assigned_conversation_new_message"
              data-test-id="push_assigned_conversation_new_message"
              :disabled="!pushNotificationEnabled"
              @input="handlePushInput"
            />
            <label for="push_assigned_conversation_new_message">
              {{
                $t(
                  'PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.ASSIGNED_CONVERSATION_NEW_MESSAGE'
                )
              }}
            </label>
          </div>
        </div>
      </div>

      <div class="profile--settings--row row push-row">
        <div class="columns small-3">
          <h4 class="block-title">
            {{ $t('GENERAL_SETTINGS.FORM.NOTIFICATIONS_SOUND_SECTION.TITLE') }}
          </h4>
          <p>
            {{ $t('GENERAL_SETTINGS.FORM.NOTIFICATIONS_SOUND_SECTION.NOTE') }}
          </p>
        </div>
        <div class="columns small-9 medium-5">
          <label>
            {{ $t('GENERAL_SETTINGS.FORM.NOTIFICATIONS_SOUND_SECTION.LABEL') }}
            <select
              v-model="notificationSoundType"
              data-test-id="account-settings-select-sound"
            >
              <option
                v-for="type in soundTypes"
                :key="type.value"
                :value="type.value"
              >
                {{ type.label }}
              </option>
            </select>
          </label>
          <label
            v-if="notificationSoundType === 'custom'"
            :class="{ error: $v.soundUrl.$error }"
          >
            {{ $t('GENERAL_SETTINGS.FORM.NOTIFICATIONS_SOUND_SECTION.URL') }}
            <input
              v-model="soundUrl"
              type="text"
              :placeholder="
                $t(
                  'GENERAL_SETTINGS.FORM.NOTIFICATIONS_SOUND_SECTION.URL_PLACEHOLDER'
                )
              "
              data-test-id="notification_sound_input"
              class="input-name-container"
              @blur="$v.soundUrl.$touch"
            />
            <span v-if="$v.soundUrl.$error" class="message">
              {{
                $t(
                  'GENERAL_SETTINGS.FORM.NOTIFICATIONS_SOUND_SECTION.URL_ERROR'
                )
              }}
            </span>
          </label>
        </div>
      </div>

      <div class="profile--settings--row row">
        <div class="columns small-3">
          <h4 class="block-title">
            {{ $t('GENERAL_SETTINGS.FORM.ACCOUNT_ID.TITLE') }}
          </h4>
          <p>
            {{ $t('GENERAL_SETTINGS.FORM.ACCOUNT_ID.NOTE') }}
          </p>
        </div>
        <div class="columns small-9 medium-5">
          <woot-code
            data-test-id="account-settings-account-id"
            :script="getAccountId"
          />
        </div>
      </div>
      <div class="current-version">
        <div>{{ `v${globalConfig.appVersion}` }}</div>
      </div>

      <woot-submit-button
        class="button nice success button--fixed-right-top"
        data-test-id="account-settings-update-button"
        :button-text="$t('GENERAL_SETTINGS.SUBMIT')"
        :loading="isUpdating"
      />
    </form>

    <woot-loading-state v-if="uiFlags.isFetchingItem" />
  </div>
</template>

<script>
import {
  required,
  minValue,
  maxValue,
  helpers,
  requiredIf,
} from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import configMixin from 'shared/mixins/configMixin';
import accountMixin from '../../../../mixins/account';
import NumberInput from '../../../../../shared/components/NumberInput.vue';
import wootConstants from '../../../../constants';
import * as types from '../../../../store/mutation-types';
import WootSubmitButton from 'components/buttons/FormSubmitButton.vue';
import WootCode from 'components/Code.vue';

const urlValidator =
  helpers.regex(
    'url',
    // eslint-disable-next-line no-useless-escape
    /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\/\w .-]*)*\/?$/
  ) || this.notificationSoundType === 'default';

export default {
  name: 'AccountSettings',
  components: { WootCode, WootSubmitButton, NumberInput },
  mixins: [accountMixin, alertMixin, configMixin],
  data() {
    return {
      id: '',
      name: '',
      locale: 'en',
      domain: '',
      supportEmail: '',
      features: {},
      autoSetAway: null,
      isAutoSetAway: false,
      autoLogoutTimespan: null,
      attachmentTtl: null,
      autoResolveDuration: null,
      autoResolveAction: 'resolve',
      maxAgentConversations: null,
      isAutoResolveValid: true,
      latestChatwootVersion: null,
      shouldAutoDeleteOnResolve: false,
      isAgentMaskingEnabled: false,
      assignToBusyAgent: false,
      pushNotificationEnabled: false,
      emailNotificationEnabled: false,
      selectedEmailFlags: [],
      selectedPushFlags: [],
      enableAawView: false,
      overrideEnableAawView: false,
      featureUseAICopilotWorkspace:
        window.chatwootConfig.featureUseAICopilotWorkspace,
      notificationSoundType: 'default',
      soundUrl: '',
    };
  },
  validations: {
    name: {
      required,
    },
    locale: {
      required,
    },
    autoResolveAction: {
      required,
    },
    autoSetAway: {
      minValue: minValue(1),
      maxValue: maxValue(999),
    },
    autoLogoutTimespan: {
      minValue: minValue(1),
      maxValue: maxValue(60),
    },
    attachmentTtl: {
      minValue: minValue(1),
      maxValue: maxValue(999),
    },
    autoResolveDuration: {
      minValue: minValue(1),
      maxValue: maxValue(999),
    },
    maxAgentConversations: {
      minValue: minValue(1),
      maxValue: maxValue(999),
    },
    notificationSoundType: {
      required,
    },
    soundUrl: {
      required: requiredIf(() => {
        return this.notificationSoundType === 'custom';
      }),
      urlValidator,
    },
  },
  computed: {
    ...mapGetters({
      globalConfig: 'globalConfig/get',
      getAccount: 'accounts/getAccount',
      currentAccount: 'getCurrentAccount',
      uiFlags: 'accounts/getUIFlags',
    }),
    languagesSortedByCode() {
      const enabledLanguages = [...this.enabledLanguages];
      return enabledLanguages.sort((l1, l2) =>
        l1.iso_639_1_code.localeCompare(l2.iso_639_1_code)
      );
    },
    soundTypes() {
      return [
        {
          value: 'default',
          label: this.$t(
            'GENERAL_SETTINGS.FORM.NOTIFICATIONS_SOUND_SECTION.DEFAULT'
          ),
        },
        {
          value: 'custom',
          label: this.$t(
            'GENERAL_SETTINGS.FORM.NOTIFICATIONS_SOUND_SECTION.CUSTOM'
          ),
        },
      ];
    },
    isUpdating() {
      return this.uiFlags.isUpdating;
    },

    getAccountId() {
      return this.id.toString();
    },
  },
  mounted() {
    if (!this.id) {
      this.initializeAccount();
    }
  },
  methods: {
    async initializeAccount() {
      try {
        await this.$store.dispatch('accounts/get');
        const {
          name,
          locale,
          id,
          domain,
          support_email,
          features,
          attachment_ttl,
          auto_set_away,
          agent_logout_timespan,
          auto_resolve_duration,
          auto_resolve_action,
          max_agent_conversations,
          should_auto_delete_on_resolve,
          is_agent_masking_enabled,
          override_push_notifications,
          override_email_notifications,
          selected_email_flags,
          selected_push_flags,
          enable_aaw_view,
          override_enable_aaw_view,
          custom_attributes,
        } = this.getAccount(this.accountId);

        this.$root.$i18n.locale = locale;
        this.name = name;
        this.locale = locale;
        this.id = id;
        this.domain = domain;
        this.supportEmail = support_email;
        this.features = features;
        this.autoSetAway = auto_set_away;
        this.autoLogoutTimespan = agent_logout_timespan;
        this.attachmentTtl = attachment_ttl;
        this.autoResolveDuration = auto_resolve_duration;
        this.autoResolveAction = auto_resolve_action;
        this.maxAgentConversations = max_agent_conversations;
        this.shouldAutoDeleteOnResolve = should_auto_delete_on_resolve;
        this.isAgentMaskingEnabled = is_agent_masking_enabled;
        this.pushNotificationEnabled = override_push_notifications;
        this.emailNotificationEnabled = override_email_notifications;
        this.selectedEmailFlags = selected_email_flags;
        this.selectedPushFlags = selected_push_flags;
        this.enableAawView = enable_aaw_view;
        this.overrideEnableAawView = override_enable_aaw_view;
        this.assignToBusyAgent = custom_attributes.assign_to_busy_agent;
        this.notificationSoundType = custom_attributes.notification_sound_type;
        this.soundUrl = custom_attributes.sound_url;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },

    async updateAccount() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.showAlert(this.$t('GENERAL_SETTINGS.FORM.ERROR'), {
          status: wootConstants.SNACKBAR_TYPE.ERROR,
        });
        return;
      }

      try {
        const body = await this.$store.dispatch('accounts/update', {
          locale: this.locale,
          name: this.name,
          domain: this.domain,
          support_email: this.supportEmail,
          auto_set_away: this.autoSetAway,
          agent_logout_timespan: this.autoLogoutTimespan,
          attachment_ttl: this.attachmentTtl,
          auto_resolve_duration: this.autoResolveDuration,
          auto_resolve_action: this.autoResolveAction,
          max_agent_conversations: this.maxAgentConversations,
          should_auto_delete_on_resolve: this.shouldAutoDeleteOnResolve,
          is_agent_masking_enabled: this.isAgentMaskingEnabled,
          override_push_notifications: this.pushNotificationEnabled,
          override_email_notifications: this.emailNotificationEnabled,
          selected_email_flags: this.selectedEmailFlags,
          selected_push_flags: this.selectedPushFlags,
          enable_aaw_view: this.enableAawView,
          override_enable_aaw_view: this.overrideEnableAawView,
          custom_attributes: {
            assign_to_busy_agent: this.assignToBusyAgent,
            notification_sound_type: this.notificationSoundType,
            sound_url: this.soundUrl,
          },
        });

        this.$root.$i18n.locale = this.locale;
        this.showAlert(this.$t('GENERAL_SETTINGS.UPDATE.SUCCESS'), {
          status: wootConstants.SNACKBAR_TYPE.SUCCESS,
        });

        this.$store.commit(
          types.default.SET_CURRENT_ACCOUNT_AGENT_MASKING_FLAG,
          body
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        this.showAlert(this.$t('GENERAL_SETTINGS.UPDATE.ERROR'), {
          status: wootConstants.SNACKBAR_TYPE.ERROR,
        });
      }
    },

    handleEmailInput(e) {
      this.selectedEmailFlags = this.toggleInput(
        this.selectedEmailFlags,
        e.target.value
      );
    },
    handlePushInput(e) {
      this.selectedPushFlags = this.toggleInput(
        this.selectedPushFlags,
        e.target.value
      );
    },
    toggleInput(selected, current) {
      if (selected.includes(current)) {
        return selected.filter(flag => flag !== current);
      }
      return [...selected, current];
    },
  },
};
</script>

<style lang="scss">
@import 'dashboard/assets/scss/variables.scss';
@import 'dashboard/assets/scss/mixins.scss';

.profile--settings {
  padding: 24px;
  overflow: auto;
}

.profile--settings--row {
  @include border-normal-bottom;
  padding: $space-normal;

  .small-3 {
    padding: $space-normal $space-medium $space-normal 0;
  }

  .small-9 {
    padding: $space-normal;
  }
}

.current-version {
  font-size: var(--font-size-small);
  text-align: center;
  padding: var(--space-normal);
}

.main-settings-section {
  @include border-normal-bottom;
  padding-bottom: 1.5rem;
}

.number-input-section {
  padding-top: 1.5rem;
  @include border-normal-top;
}

.toggle-checkbox-label-container {
  margin-left: 1rem;
  max-width: 90%;
}

.toggle-checkbox-container {
  padding: 1.5rem 0;
  @include border-normal-top;
  display: flex;
  align-items: start;

  > input {
    margin-top: 0.5rem;
  }
}

.warning-label {
  color: #cc0000;
}
.auto-resolve-action-container {
  padding-bottom: 1.5rem;
}

.title-with-beta-tag {
  display: flex;
  align-items: center;
}

.beta-tag {
  margin-left: 5px;
  font-size: small;
  text-align: center;
  padding-top: 4px;
  font-weight: bold;
  background-color: #a857b5;
  color: white;
  border-radius: 10px;
  width: 50px;
  margin-bottom: 5px;
}
</style>
