import ApiClient, { axiosWithAuth } from './ApiClient';

class CustomViewsAPI extends ApiClient {
  constructor() {
    super('custom_filters', { accountScoped: true });
  }

  // getCustomViewsByFilterType(type) {
  // eslint-disable-next-line class-methods-use-this
  getCustomViewsByFilterType() {
    // TODO 55617 review this API call
    // return axiosWithAuth.get(`${this.url}?filter_type=${type}`);
    return {
      data: [],
    };
  }

  deleteCustomViews(id, type) {
    return axiosWithAuth.delete(`${this.url}/${id}?filter_type=${type}`);
  }
}

export default new CustomViewsAPI();
