import AuthAPI from '../api/auth';
import BaseActionCableConnector from '../../shared/helpers/BaseActionCableConnector';
import { newMessageNotification } from 'shared/helpers/AudioNotificationHelper';

class ActionCableConnector extends BaseActionCableConnector {
  constructor(app, pubSubToken) {
    const { websocketURL = '' } = window.chatwootConfig || {};
    super(app, pubSubToken, websocketURL);
    this.CancelTyping = [];
    this.events = {
      'message.created': this.onMessageCreated,
      'message.updated': this.onMessageUpdated,
      'conversation.created': this.onConversationCreated,
      'conversation.status_changed': this.onStatusChange,
      'user:logout': ActionCableConnector.onLogout,
      'page:reload': ActionCableConnector.onReload,
      'assignee.changed': this.onAssigneeChanged,
      'conversation.typing_on': this.onTypingOn,
      'conversation.typing_off': this.onTypingOff,
      'conversation.contact_changed':
        ActionCableConnector.onConversationContactChange,
      'presence.update': this.onPresenceUpdate,
      'contact.deleted': this.onContactDelete,
      'contact.updated': this.onContactUpdate,
      'conversation.mentioned': this.onConversationMentioned,
      'notification.created': this.onNotificationCreated,
      'conversation.read': this.onConversationRead,
      'conversation.inbox_changed': this.onInboxChange,
    };
  }

  onMessageUpdated = data => {
    this.app.$store.dispatch('updateMessage', data).then(r => r);
  };

  onPresenceUpdate = data => {
    // TODO: 36826 create a separate socket event for updating the agents presence
    this.app.$store
      .dispatch('contacts/updatePresence', data.contacts)
      .then(r => r);
    this.app.$store
      .dispatch('agents/updatePresenceForAll', data.users)
      .then(r => r);
    this.app.$store
      .dispatch('setCurrentUserAvailability', data.users)
      .then(r => r);
  };

  // Method commented for now as this event won't include cognigy user profile
  // onConversationContactChange = payload => {
  //   const { meta = {}, id: conversationId } = payload;
  //   const { sender } = meta || {};
  //   if (conversationId) {
  //     this.app.$store.dispatch('updateConversationContact', {
  //       conversationId,
  //       ...sender,
  //     });
  //   }
  // };
  static onConversationContactChange = () => {
    // const { meta = {}, id: conversationId } = payload;
    // const { sender } = meta || {};
    // if (conversationId) {
    //   this.app.$store.dispatch('updateConversationContact', {
    //     conversationId,
    //     ...sender,
    //   });
    // }
  };

  onAssigneeChanged = payload => {
    const { id } = payload;
    if (id) {
      this.app.$store.dispatch('updateConversation', payload).then(r => r);
    }
    ActionCableConnector.fetchConversationStats();
  };

  onConversationCreated = data => {
    this.app.$store.dispatch('addConversation', data).then(r => r);
    ActionCableConnector.fetchConversationStats();
  };

  onConversationRead = data => {
    const { contact_last_seen_at: lastSeen } = data;
    this.app.$store.dispatch('updateConversationRead', lastSeen).then(r => r);
  };

  static onLogout = () => AuthAPI.logout();

  onMessageCreated = data => {
    const {
      conversation: { last_activity_at: lastActivityAt },
      conversation_id: conversationId,
    } = data;

    newMessageNotification(data);

    this.app.$store.dispatch('addMessage', data).then(r => r);
    this.app.$store
      .dispatch('updateConversationLastActivity', {
        lastActivityAt,
        conversationId,
      })
      .then(r => r);
  };

  static onReload = () => window.location.reload();

  onStatusChange = data => {
    this.app.$store.dispatch('updateConversation', data).then(r => r);
    ActionCableConnector.fetchConversationStats();
  };

  onTypingOn = ({ conversation, user }) => {
    const conversationId = conversation.id;

    this.clearTimer(conversationId);
    this.app.$store
      .dispatch('conversationTypingStatus/create', {
        conversationId,
        user,
      })
      .then(r => r);
    this.initTimer({ conversation, user });
  };

  onTypingOff = ({ conversation, user }) => {
    const conversationId = conversation.id;

    this.clearTimer(conversationId);
    this.app.$store
      .dispatch('conversationTypingStatus/destroy', {
        conversationId,
        user,
      })
      .then(r => r);
  };

  onConversationMentioned = data => {
    this.app.$store.dispatch('addMentions', data).then(r => r);
  };

  clearTimer = conversationId => {
    const timerEvent = this.CancelTyping[conversationId];

    if (timerEvent) {
      clearTimeout(timerEvent);
      this.CancelTyping[conversationId] = null;
    }
  };

  initTimer = ({ conversation, user }) => {
    const conversationId = conversation.id;
    // Turn off typing automatically after 30 seconds
    this.CancelTyping[conversationId] = setTimeout(() => {
      this.onTypingOff({ conversation, user });
    }, 30000);
  };

  static fetchConversationStats = () => {
    bus.$emit('fetch_conversation_stats');
  };

  onNotificationCreated = data => {
    this.app.$store
      .dispatch('notifications/addNotification', data)
      .then(r => r);
  };

  onContactDelete = data => {
    this.app.$store
      .dispatch('contacts/deleteContactThroughConversations', data.id)
      .then(r => r);
    ActionCableConnector.fetchConversationStats();
  };

  onContactUpdate = data => {
    this.app.$store.dispatch('contacts/updateContact', data).then(r => r);
  };

  onInboxChange = data => {
    this.app.$store
      .dispatch('updateInboxOnConversations', {
        conversationIds: [data.id],
        inboxId: data.inbox_id,
      })
      .then(r => r);

    ActionCableConnector.fetchConversationStats();
  };
}

export default {
  init(pubSubToken) {
    return new ActionCableConnector(window.WOOT, pubSubToken);
  },
};
